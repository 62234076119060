module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Ján Ďurovčík Entertainment","short_name":"Ján Ďurovčík","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"C:\\Users\\Martin\\Desktop\\Workspace\\jan-durovcik/src/assets/icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"d42d864e2c3aedb33013f8984c618409"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"G-41CX1LRV59","head":false,"exclude":["/production/*","/404/"],"optimizeId":"G-41CX1LRV59","anonymize":false,"respectDNT":false,"pageTransitionDelay":0},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
