import React, { useState, createContext } from "react"

import { MUSICALS, MUSICALS_CATEGORY, BALLET_OPERA, BALLET_OPERA_CATEGORY, SHOW, SHOW_CATEGORY, THEATRE_DANCE, THEATRE_DANCE_CATEGORY, UPCOMING } from '../assets/data';

export const PerformancesCtx = createContext()

export const getPerformances = (categoryName, withoutUpcoming) => {
    let tmpPerformances = []
    if (categoryName === MUSICALS_CATEGORY) {
        tmpPerformances = MUSICALS;
    } else if (categoryName === BALLET_OPERA_CATEGORY) {
        tmpPerformances = BALLET_OPERA;
    } else if (categoryName === SHOW_CATEGORY) {
        tmpPerformances = SHOW;
    } else if (categoryName === THEATRE_DANCE_CATEGORY){
        tmpPerformances = THEATRE_DANCE;
    } else {
        // all performances
        tmpPerformances = getAllPerformances()
    }
    if(withoutUpcoming) {
        tmpPerformances = tmpPerformances.filter(tmpPerformance => tmpPerformance.unique !== UPCOMING)
    }
    return tmpPerformances
}

// function return ordered list by own preferences
const getAllPerformances = () => {
    const tmpMusicals = [...MUSICALS]
    const tmpBalletOpera = [...BALLET_OPERA]
    const tmpShow = [...SHOW]
    const tmpTheatreDance = [...THEATRE_DANCE]

    const orderedPerformances = [];
    orderedPerformances.push(tmpMusicals.shift()) // saturday-night-fever
    orderedPerformances.push(tmpBalletOpera.shift()) // carmen
    orderedPerformances.push(tmpBalletOpera.shift()) // chorea
    orderedPerformances.push(tmpMusicals.shift()) // jesus-christ-superstar
    orderedPerformances.push(tmpMusicals.shift()) // west-side-story
    orderedPerformances.push(tmpMusicals.shift()) // water-and-blood
    orderedPerformances.push(tmpTheatreDance.shift()) // romeo+juliet
    orderedPerformances.push(tmpTheatreDance.shift()) // richard-iii
    orderedPerformances.push(tmpMusicals.shift()) // rasputin
    orderedPerformances.push(tmpMusicals.shift()) // robin-hood
    orderedPerformances.push(tmpMusicals.shift()) // grease
    orderedPerformances.push(tmpMusicals.shift()) // school-of-rock
    orderedPerformances.push(tmpBalletOpera.shift()) // spartacus
    orderedPerformances.push(tmpBalletOpera.shift()) // the-abduction-from-the-seraglio
    orderedPerformances.push(tmpMusicals.shift()) // the-lady-with-camellias
    orderedPerformances.push(tmpBalletOpera.shift()) // symphony-of-psalms
    orderedPerformances.push(tmpMusicals.shift()) // romeo-and-juliet
    orderedPerformances.push(tmpBalletOpera.shift()) // the-hunchback-of-notre-dame
    orderedPerformances.push(tmpBalletOpera.shift()) // the-rite-of-spring
    orderedPerformances.push(tmpTheatreDance.shift()) // bolero
    orderedPerformances.push(tmpMusicals.shift()) // atlantis
    orderedPerformances.push(tmpBalletOpera.shift()) // rasputin-opera
    orderedPerformances.push(tmpBalletOpera.shift()) // the-magic-flute
    orderedPerformances.push(tmpMusicals.shift()) // the-cream-princess
    orderedPerformances.push(tmpBalletOpera.shift()) // labutko
    orderedPerformances.push(tmpShow.shift()) // the-duchons
    orderedPerformances.push(tmpMusicals.shift()) // bambulka
    orderedPerformances.push(tmpBalletOpera.shift()) // popolvar
    orderedPerformances.push(tmpTheatreDance.shift()) // the-tree
    orderedPerformances.push(tmpTheatreDance.shift()) // firebird
    orderedPerformances.push(tmpBalletOpera.shift()) // my-country
    orderedPerformances.push(tmpShow.shift()) // circus-world
    orderedPerformances.push(tmpMusicals.shift()) // painted-on-the-glass
    orderedPerformances.push(tmpTheatreDance.shift()) // i-shall-spit-on-your-graves
    orderedPerformances.push(tmpMusicals.shift()) // chicks
    orderedPerformances.push(tmpShow.shift()) // gasp

    orderedPerformances.push(...tmpMusicals);
    orderedPerformances.push(...tmpBalletOpera);
    orderedPerformances.push(...tmpShow);
    orderedPerformances.push(...tmpTheatreDance);
    return orderedPerformances;
}

export function PerformancesProvider({ children }) {
    return (
        <PerformancesCtx.Provider value={{ getPerformances }}>
            {children}
        </PerformancesCtx.Provider>
    )
}
