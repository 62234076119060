// values are also keys to language resources
export const MUSICALS_CATEGORY = "production.musical"
export const BALLET_OPERA_CATEGORY = "production.balletopera"
export const SHOW_CATEGORY = "production.show"
export const THEATRE_DANCE_CATEGORY = "production.theatredance"
export const UPCOMING = null
    
export const toggleFullScreen = () => {
    var doc = window.document;
    var docEl = doc.documentElement;
  
    var requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
    var cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
  
    if(!doc.fullscreenElement && !doc.mozFullScreenElement && !doc.webkitFullscreenElement && !doc.msFullscreenElement) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
}

export const getMinTheatrePremiere = (theatre) => {
    if(theatre.premiere.length === 0) {
        return 0
    }
    return Math.min.apply(Math, theatre.premiere.map(tmpPremiere => tmpPremiere))
}

// status: 0 - performance has end
//         1 - performance is actual
//         2 - performance is only planned
//         3 - performance is festival


// image name:
//  0 - first after click
//  1 - production
//  2 - homepage


// UPCOMING template:
//{
//   id: 0,
//   unique: 'school-of-rock',
//   category: MUSICALS_CATEGORY,
//   theaters: []
//},

// UPCOMING translate template:
// "school-of-rock.name": "Škola ro(c)ku",
// "school-of-rock.premiere": "Jeseň 2021",
// "school-of-rock.category-text": "nový moderný balet",
// "school-of-rock.desc": "Slovenské divadlo tanca pripravuje po 10 rokov úplne novú tanečnú inscenáciu v réžii Jána Ďurovčíka. V marci a apríli na celoslovenskom turné - vstupenky v sieti TicketPortal a Predpredaj.sk",
// "school-of-rock.link": "<a href='https://youtu.be/KWq_3gKM7Ws' target='_blank'>Amadeus spot</a>",

export const MUSICALS = [
    {
        id: 1,
        unique: 'saturday-night-fever',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/vg9bmKCqLjk',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true],
        reviews_count: 5,
        theaters: [
            {
                name: "kalich",
                location: "prague",
                premiere: [2014],
                derniere: 0,
                status: 1
            }
        ]
    },
    {
        id: 2,
        unique: 'jesus-christ-superstar',
        category: MUSICALS_CATEGORY,
        trailer: null,
        spot: 'https://www.youtube.com/embed/wjwPeWYJZ2g',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 5,
        theaters: [
            {
                name: "tower-stage",
                location: "bratislava",
                premiere: [2015],
                derniere: 2019,
                status: 0
            }
        ]
    },
    {
        id: 3,
        unique: 'west-side-story',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/CCynC2N1i_M',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 4,
        theaters: [
            {
                name: "kalich",
                location: "bratislava",
                premiere: [2008],
                derniere: 2014,
                status: 0
            }
        ]
    },
    {
        id: 4,
        unique: 'water-and-blood',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/K9QtMMn8GbY',
        spot: null,
        is_inspired: true,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 13,
        theaters: [
            {
                name: "nova-scena",
                location: "bratislava",
                premiere: [2019],
                derniere: 0,
                status: 1
            },
            {
                name: "kalich",
                location: "prague",
                premiere: [2019],
                derniere: 0,
                status: 1
            }
        ]
    },
    {
        id: 5,
        unique: 'rasputin',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/oaXnviLWcH8',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true,true],
        reviews_count: 4,
        theaters: [
            {
                name: "nova-scena",
                location: "bratislava",
                premiere: [2018],
                derniere: 0,
                status: 1
            }
        ]
    },
    {
        id: 6,
        unique: 'robin-hood',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/RZPnCOkzZJk',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 5,
        theaters: [
            {
                name: "kalich",
                location: "prague",
                premiere: [2010],
                derniere: 0,
                status: 1
            }
        ]
    },
    {
        id: 7,
        unique: 'grease',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/vbompEFLP0w',
        spot: 'https://www.youtube.com/embed/Bifr6NWtfTM',
        images: [true,true,true,true,true,true,true,true,true],
        reviews_count: 10,
        theaters: [
            {
                name: "kalich",
                location: "prague",
                premiere: [2012],
                derniere: 0,
                status: 1
            },
            {
                name: null,
                location: "bratislava",
                premiere: [],
                derniere: 0,
                status: 2
            }
        ]
    },
    {
        id: 8,
        unique: 'school-of-rock',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/1t7pZpiOSrg',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 0,
        theaters: [{
            name: "iuventa",
            location: "bratislava",
            premiere: [2021],
            derniere: 0,
            status: 1
        }]
    },
    {
        id: 9,
        unique: 'the-lady-with-camellias',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/AX_1WrEJ7R8',
        spot: 'https://www.youtube.com/embed/imOifNSLo9E',
        is_inspired: true,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 11,
        theaters: [
            {
                name: "kalich",
                location: "prague",
                premiere: [2011],
                derniere: 0,
                status: 1
            },
            {
                name: "nova-scena",
                location: "bratislava",
                premiere: [2011],
                derniere: 0,
                status: 1
            }
        ]
    },
    {
        id: 10,
        unique: 'romeo-and-juliet',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/qleDuVdRwXU',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 5,
        theaters: [
            {
                name: "nova-scena",
                location: "bratislava",
                premiere: [2013],
                derniere: 2020,
                status: 0
            }
        ]
    },
    {
        id: 11,
        unique: 'atlantis',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/dUvnK8NJR-A',
        spot: 'https://www.youtube.com/embed/zGxZ7vWr7BQ',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 9,
        theaters: [
            {
                name: "kalich",
                location: "prague",
                premiere: [2016],
                derniere: 0,
                status: 1
            },
            {
                name: "nova-scena",
                location: "bratislava",
                premiere: [2016],
                derniere: 2019,
                status: 0
            }
        ]
    },
    {
        id: 12,
        unique: 'the-cream-princess',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/MT3Dl8YlfxY',
        spot: 'https://www.youtube.com/embed/t0xLoS_9pls',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 3,
        theaters: [
            {
                name: "kalich",
                location: "prague",
                premiere: [2016],
                derniere: 0,
                status: 1
            },
        ]
    },
    {
        id: 13,
        unique: 'bambulka',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/j1jX__IoZF0',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 3,
        theaters: [
            {
                name: "istropolis",
                location: "bratislava",
                premiere: [2018],
                derniere: 2020,
                status: 0
            },
        ]
    },
    {
        id: 14,
        unique: 'painted-on-the-glass',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/kJ9QJrpC0KI',
        spot: 'https://www.youtube.com/embed/QDek4LLMlmU',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 5,
        theaters: [
            {
                name: "tower-stage",
                location: "bratislava",
                premiere: [2014],
                derniere: 0,
                status: 1
            },
        ]
    },
    {
        id: 15,
        unique: 'chicks',
        category: MUSICALS_CATEGORY,
        trailer: 'https://www.youtube.com/embed/gF1MjeT-Vc0',
        spot: 'https://www.youtube.com/embed/6qm4MzVND6E',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 5,
        theaters: [
            {
                name: "iuventa",
                location: "bratislava",
                premiere: [2009, 2022],
                derniere: 0,
                status: 1
            },
        ]
    },
    {
        id: 16,
        unique: 'confession',
        category: MUSICALS_CATEGORY,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 6,
        theaters: [
            {
                name: "iuventa",
                location: "bratislava",
                premiere: [2022],
                derniere: 0,
                status: 1
            },
        ]
    }
];

export const BALLET_OPERA = [
    {
        id: 1,
        unique: 'carmen',
        category: BALLET_OPERA_CATEGORY,
        trailer: 'https://www.youtube.com/embed/VQXjRknyWUA',
        spot: 'https://www.youtube.com/embed/BBlSBpWUbVo',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 5,
        theaters: [
            {
                name: "tower-stage",
                location: "bratislava",
                premiere: [2010],
                derniere: 0,
                status: 1
            }
        ]
    },
    {
        id: 2,
        unique: 'chorea',
        category: BALLET_OPERA_CATEGORY,
        trailer: 'https://www.youtube.com/embed/QbLT3hf5zNg',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 3,
        theaters: [
            {
                name: 'national-festival',
                location: 'bratislava',
                premiere: [],
                derniere: 0,
                status: 3
            }
        ]
    },
    {
        id: 3,
        unique: 'spartacus',
        category: BALLET_OPERA_CATEGORY,
        trailer: 'https://www.youtube.com/embed/mPLaK4naLa8',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 4,
        theaters: [
            {
                name: "slovak-national-theatre",
                location: "bratislava",
                premiere: [2004],
                derniere: 2009,
                status: 0
            }
        ]
    },
    {
        id: 4,
        unique: 'the-abduction-from-the-seraglio',
        category: BALLET_OPERA_CATEGORY,
        trailer: 'https://www.youtube.com/embed/99kn_LpFB8k',
        spot: 'https://www.youtube.com/embed/4aGGoMob3B8',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 4,
        theaters: [
            {
                name: "slovak-national-theatre",
                location: "bratislava",
                premiere: [2017],
                derniere: 0,
                status: 1
            }
        ]
    },
    {
        id: 5,
        unique: 'symphony-of-psalms',
        category: BALLET_OPERA_CATEGORY,
        trailer: 'https://www.youtube.com/embed/7yJlJgokB5w',
        spot: null,
        images: [true,true,true],
        reviews_count: 4,
        theaters: [
            {
                name: "brno-national-theatre",
                location: "brno",
                premiere: [2000],
                derniere: 2002,
                status: 0
            }
        ]
    },
    {
        id: 6,
        unique: 'the-hunchback-of-notre-dame',
        category: BALLET_OPERA_CATEGORY,
        trailer: null,
        spot: 'https://www.youtube.com/embed/bPBME-xml6Q',
        images: [true,true,true,true,true,true,true,true,true,true],
        reviews_count: 5,
        theaters: [
            {
                name: "kosice-state-theatre",
                location: "kosice",
                premiere: [2012],
                derniere: 2017,
                status: 0
            }
        ]
    },
    {
        id: 7,
        unique: 'the-rite-of-spring',
        category: BALLET_OPERA_CATEGORY,
        trailer: 'https://www.youtube.com/embed/gjZycYMsbng',
        spot: null,
        images: [true,true,true,true,true],
        reviews_count: 5,
        theaters: [
            {
                name: "slovak-national-theatre",
                location: "bratislava",
                premiere: [1996],
                derniere: 2002,
                status: 0
            }
        ]
    },
    {
        id: 8,
        unique: 'rasputin-opera',
        category: BALLET_OPERA_CATEGORY,
        trailer: 'https://www.youtube.com/embed/ZhzS57MRO1w',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 3,
        theaters: [
            {
                name: "slovak-national-theatre",
                location: "bratislava",
                premiere: [2003],
                derniere: 2010,
                status: 0
            }
        ]
    },
    {
        id: 9,
        unique: 'the-magic-flute',
        category: BALLET_OPERA_CATEGORY,
        trailer: null,
        spot: null,
        images: [true,true,true,true],
        reviews_count: 4,
        theaters: [
            {
                name: "tyl-theatre",
                location: "plzen",
                premiere: [1999],
                derniere: 2005,
                status: 0
            }
        ]
    },
    {
        id: 10,
        unique: 'labutko',
        category: BALLET_OPERA_CATEGORY,
        trailer: 'https://www.youtube.com/embed/130Mo3uVksM',
        spot: 'https://www.youtube.com/embed/YcMh6gFkdF4',
        images: [true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 4,
        theaters: [
            {
                name: "slovak-national-theatre",
                location: "bratislava",
                premiere: [2007],
                derniere: 2011,
                status: 0
            }
        ]
    },
    {
        id: 11,
        unique: 'popolvar',
        category: BALLET_OPERA_CATEGORY,
        trailer: 'https://www.youtube.com/embed/z03mgdENDMM',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 4,
        theaters: [
            {
                name: "slovak-national-theatre",
                location: "bratislava",
                premiere: [2007],
                derniere: 0,
                status: 1
            }
        ]
    },
    {
        id: 12,
        unique: 'my-country',
        category: BALLET_OPERA_CATEGORY,
        trailer: 'https://www.youtube.com/embed/M1I4wpnGvec',
        spot: null,
        images: [true,true,true,true,true,true,true,true],
        reviews_count: 4,
        theaters: [
            {
                name: "prague-state-opera",
                location: "prague",
                premiere: [2005],
                derniere: 2010,
                status: 0
            }
        ]
    },
    {
        id: 13,
        unique: 'goblin',
        category: BALLET_OPERA_CATEGORY,
        trailer: null,
        spot: null,
        images: [true,true,true,true,true],
        reviews_count: 4,
        theaters: [
            {
                name: "brno-national-theatre",
                location: "brno",
                premiere: [1997],
                derniere: 2001,
                status: 0
            }
        ]
    },
    {
        id: 14,
        unique: 'amadeus',
        category: BALLET_OPERA_CATEGORY,
        trailer: null,
        spot: 'https://www.youtube.com/embed/2vEBlgBKR-Q',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 6,
        theaters: [
            {
                name: 'dk-trnava',
                location: 'trnava',
                premiere: [2024],
                status: 1
            },
        ]
    },
];

export const SHOW = [
    {
        id: 1,
        unique: 'the-duchons',
        category: SHOW_CATEGORY,
        trailer: 'https://www.youtube.com/embed/jLHMjHD7QmE',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 9,
        theaters: [
            {
                name: "chemkostav",
                location: "michalovce",
                premiere: [2018],
                derniere: 0,
                status: 1
            }
        ]
    },
    {
        id: 2,
        unique: 'circus-world',
        category: SHOW_CATEGORY,
        trailer: 'https://www.youtube.com/embed/zRWK_XsqJHw',
        spot: null,
        images: [true,true,true,true,true,true,true],
        reviews_count: 3,
        theaters: [
            {
                name: null,
                location: null,
                premiere: [2006],
                derniere: 2010,
                status: 0
            },
        ]
    },
    {
        id: 3,
        unique: 'gasp',
        category: SHOW_CATEGORY,
        trailer: 'https://www.youtube.com/embed/-StlrlohijI',
        spot: 'https://www.youtube.com/embed/jHzIQu4qtkM',
        images: [true,true,true,true,true,true,true,true,true],
        reviews_count: 3,
        theaters: [
            {
                name: "tower-stage",
                location: "bratislava",
                premiere: [2013],
                derniere: 0,
                status: 1
            },
            {
                name: "arena-theatre",
                location: "bratislava",
                premiere: [2013],
                derniere: 0,
                status: 1
            },
        ]
    },
    {
        id: 4,
        unique: 'imt-smile',
        category: SHOW_CATEGORY,
        trailer: null,
        spot: 'https://www.youtube.com/embed/mUWU5GIrAD4',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 3,
        theaters: [
            {
                name: null,
                location: null,
                premiere: [2014],
                derniere: 2017,
                status: 0
            }
        ]
    },
];

export const THEATRE_DANCE = [
    {
        id: 1,
        unique: 'romeo+juliet',
        category: THEATRE_DANCE_CATEGORY,
        trailer: 'https://www.youtube.com/embed/XrE_aC5dPjM',
        spot: null,
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 9,
        theaters: [
            {
                name: "iuventa",
                location: "bratislava",
                premiere: [2004,2020],
                derniere: 0,
                status: 1
            }
        ]
    },
    {
        id: 2,
        unique: 'richard-iii',
        category: THEATRE_DANCE_CATEGORY,
        trailer: 'https://www.youtube.com/embed/q1F7ST0Tcyo',
        spot: 'https://www.youtube.com/embed/gDLfasoWz2w',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 3,
        theaters: [
            {
                name: "graz-opera",
                location: "graz",
                premiere: [2013],
                derniere: 2016,
                status: 0
            },
            {
                name: "tower-stage",
                location: "bratislava",
                premiere: [2017],
                derniere: 0,
                status: 1
            },
        ]
    },
    {
        id: 3,
        unique: 'bolero',
        category: THEATRE_DANCE_CATEGORY,
        trailer: 'https://www.youtube.com/embed/29_BEW4veFM',
        spot: null,
        images: [true,true,true,true,true,true,true,true],
        reviews_count: 4,
        theaters: [
            {
                name: "slovak-dance-theatre",
                location: "bratislava",
                premiere: [2005],
                derniere: 2008,
                status: 0
            }
        ]
    },
    {
        id: 4,
        unique: 'the-tree',
        category: THEATRE_DANCE_CATEGORY,
        trailer: null,
        spot: 'https://www.youtube.com/embed/z-OI4d1PoLE',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 6,
        theaters: [
            {
                name: "istropolis",
                location: "bratislava",
                premiere: [1999,2018],
                derniere: 0,
                status: 1
            }
        ]
    },
    {
        id: 5,
        unique: 'firebird',
        category: THEATRE_DANCE_CATEGORY,
        trailer: 'https://www.youtube.com/embed/ZfHBnUdgXB4',
        spot: 'https://www.youtube.com/embed/67H1n3cpVsU',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 10,
        theaters: [
            {
                name: "tokyo-metropolitan",
                location: "tokyo",
                premiere: [2004],
                derniere: 0,
                status: 1
            },
            {
                name: "tower-stage",
                location: "bratislava",
                premiere: [2008],
                derniere: 0,
                status: 1
            }
        ]
    },
    {
        id: 6,
        unique: 'i-shall-spit-on-your-graves',
        category: THEATRE_DANCE_CATEGORY,
        trailer: 'https://www.youtube.com/embed/U7bLFjnMBCc',
        spot: 'https://www.youtube.com/embed/I4xpq7S6qpE',
        images: [true,true,true,true,true,true,true,true,true,true,true,true,true],
        reviews_count: 4,
        theaters: [
            {
                name: "slovak-dance-theatre",
                location: "bratislava",
                premiere: [2006],
                derniere: 2016,
                status: 0
            },
        ]
    },
];