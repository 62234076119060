import React, { useState, createContext } from "react"

export const CategoryCtx = createContext()

export function CategoryProvider({ children }) {
    const [selectedCategory, setSelectedCategory] = useState(null)

    return (
        <CategoryCtx.Provider value={{ selectedCategory, setSelectedCategory }}>
            {children}
        </CategoryCtx.Provider>
    )
}