import React, { useEffect } from "react"

import { CategoryProvider } from './CategoryCtx'
import { PerformancesProvider } from './PerformancesCtx'
import { LanguageProvider } from './LanguageCtx'
import '../assets/css/main.scss'

import CssBaseline from '@material-ui/core/CssBaseline';

function RootLayout({ children }) {
    useEffect(() => {
        setHeightOfScreen()
    }, [])

    const setHeightOfScreen = () => {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        let vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }

    return (
        <CategoryProvider>
            <PerformancesProvider>
                <LanguageProvider>
                    <CssBaseline/>
                    {children}
                </LanguageProvider>
            </PerformancesProvider>
        </CategoryProvider>
    )
}

export default RootLayout