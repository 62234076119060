import React, { useState, createContext, useContext } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { dictionaryList } from '../languages';

export const LanguageCtx = createContext();

export function LanguageProvider({ children }) {
  let defaultLanguage;
  if (typeof window !== 'undefined') {
    defaultLanguage = localStorage.getItem('rcml-lang');
  }
  if (!defaultLanguage) {
    defaultLanguage = 'sk'
  }
  const [userLanguage, setUserLanguage] = useState(defaultLanguage);

  const userLanguageChange = (newLanguage) => {
    setUserLanguage(newLanguage);
    localStorage.setItem('rcml-lang', newLanguage);
  }

  const getText = (tid) => {
    const html = ReactHtmlParser(dictionaryList[userLanguage][tid])[0] || ""
    return html
  }

  return (
    <LanguageCtx.Provider value={{ userLanguage, dictionary: dictionaryList[userLanguage], userLanguageChange, getText }}>
      {children}
    </LanguageCtx.Provider>
  );
};

// get text according to id & current language
export function Text({ tid }) {
  const languageCtxt = useContext(LanguageCtx);
  const html = languageCtxt.dictionary[tid] || "" 
  return ReactHtmlParser(html)
};